<template>
  <b-container fluid>
    <div
      v-if="companiesLoading"
      class="d-flex justify-content-center mb-3 mt-5"
    >
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      ></b-spinner>
      <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
    </div>
    <b-row v-else-if="company">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <div>
              <span class="h4">{{ company.name }}</span>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("companies.details") }}</h4>
          </template>
          <template v-slot:body>
            <div>
              <strong
                ><i class="ri-global-line"></i> {{ $t("companies.name") }}:
              </strong>
              {{ company.name }}
            </div>
            <div>
              <span>
                <strong
                  ><i class="ri-global-line"></i>
                  {{ $t("companies.business-name") }}:
                </strong>
                {{ company.business_name }} </span
              ><br />
              <span>
                <strong
                  ><i class="ri-global-line"></i> {{ $t("companies.rut") }}:
                </strong>
                {{ company.rut }}
              </span>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="12">
            <list-contracts> </list-contracts>
          </b-col>
          <b-col md="12">
            <list-users> </list-users>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ListContracts from "../Contracts";
import ListUsers from "../Users";
export default {
  components: {
    ListContracts,
    ListUsers,
  },
  mounted() {
    this.fetchCompany(this.$route.params.id);
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    ...mapActions({
      fetchCompany: "fetchCompany",
    }),
  },
  computed: {
    ...mapGetters({
      company: "company",
      companiesLoading: "companiesLoading",
    }),
  },
};
</script>

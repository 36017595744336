var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{},[_vm._v(" "+_vm._s(_vm.$t("companies.contracts"))+" ")])]},proxy:true},{key:"headerAction",fn:function(){return [_c('b-button',{staticClass:"mr-1 float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.modalFormContract.show(null)}}},[_vm._v(_vm._s(_vm.$t("companies.add-contract")))])]},proxy:true},{key:"body",fn:function(){return [_c('b-col',{staticClass:"table-responsive",attrs:{"md":"12"}},[_c('b-table',{staticClass:"table mb-0 table-borderless",attrs:{"items":_vm.contracts,"fields":_vm.columns,"busy":!Array.isArray(_vm.contracts) || _vm.contracts.length === 0
              ? true
              : false || _vm.isBusy == true},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.isBusy)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(" Cargando datos...")])],1):_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.$t("app.not-found")))])])]},proxy:true},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":" iq-bg-warning rounded","size":"sm"},on:{"click":function($event){return _vm.$refs.modalFormContract.show(data.item)}}},[_c('i',{staticClass:"ri-ball-pen-fill ml-1"})]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":" iq-bg-primary rounded","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'admin.show-contract',
                  params: {
                    id: data.item.id,
                    companyId: _vm.$route.params.id,
                  },
                })}}},[_c('i',{staticClass:"ri-eye-fill ml-1"})]),_c('b-button',{attrs:{"variant":" iq-bg-danger rounded","size":"sm"},on:{"click":function($event){return _vm.deleteItem(data.item.id)}}},[_c('i',{staticClass:"ri-delete-bin-line ml-1"})])]}}])})],1)]},proxy:true}])}),_c('modal-form-contract',{ref:"modalFormContract"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-modal id="modal-contract" hide-footer size="lg">
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("contracts.new") : $t("contracts.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="center">
            <b-col md="6" sm="12">
              <b-row align-v="center">
                <!-- Service -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('contracts.services')"
                  label-for="ad_service_id"
                >
                  <ValidationProvider
                    :name="$t('contracts.services')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="data.ad_service_id"
                      :placeholder="$t('contracts.services')"
                      :options="adServices"
                      label="name"
                      :reduce="(item) => item.id"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                      <template #no-options="{}">
                        {{ $t("app.not-found") }}...
                      </template>
                    </v-select>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group
                class="col-md-12"
                :label="$t('contracts.expired-date')"
                label-for="expired_date"
              >
                <ValidationProvider
                  :name="$t('contracts.expired-date')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <date-picker
                    v-model="data.expired_date"
                    :lang="'es'"
                    type="date"
                    format="DD-MM-YYYY"
                    value-type="YYYY-MM-DD"
                    :input-class="`form-control ${
                      errors.length > 0 ? ' is-invalid' : ''
                    }`"
                    style="width: 100% !important"
                    :placeholder="$t('contracts.expired-date-placeholder')"
                  ></date-picker>
                  <div class="text-danger">
                    <small>{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="contractsLoading"
            variant="success"
            type="submit"
          >
            <span v-if="contractsLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-contract')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "CreateOrUpdateContract",
  components: { DatePicker },
  mounted() {
    core.index();
    this.fetchAdServices();
  },
  data() {
    return {
      data: {
        id: null,
        ad_service_id: null,
        expired_date: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "createOrUpdateContract",
      fetchAdServices: "fetchAdServices",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.data = {
          id: item.id,
          ad_service_id: item.ad_service_id,
          expired_date: item.expired_date,
        };
      }
      this.$bvModal.show("modal-contract");
    },
    async onSubmit() {
      const resp = await this.saveForm({
        id: this.data.id,
        ad_service_id: this.data.ad_service_id.id
          ? this.data.ad_service_id.id
          : this.data.ad_service_id,
        expired_date: moment(this.data.expired_date).format("YYYY-MM-DD"),
        company_id: this.$route.params.id,
      });
      this.$bvModal.hide("modal-contract");
      this.$emit("refresh");
      if (resp?.status === 201 || resp?.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("contracts.success-created")
            : this.$t("contracts.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        ad_service_id: null,
        expired_date: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      adServices: "adServices",
      contractsLoading: "contractsLoading",
    }),
  },
};
</script>

<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="">
          {{ $t("companies.contracts") }}
        </h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          class="mr-1 float-right"
          @click="$refs.modalFormContract.show(null)"
          variant="primary"
          >{{ $t("companies.add-contract") }}</b-button
        >
      </template>
      <template v-slot:body>
        <b-col md="12" class="table-responsive">
          <b-table
            class="table mb-0 table-borderless"
            :items="contracts"
            :fields="columns"
            :busy="
              !Array.isArray(contracts) || contracts.length === 0
                ? true
                : false || isBusy == true
            "
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <div v-if="isBusy">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <strong> Cargando datos...</strong>
                </div>
                <span class="h5" v-else>{{ $t("app.not-found") }}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-button
                variant=" iq-bg-warning rounded"
                class="mr-1"
                size="sm"
                @click="$refs.modalFormContract.show(data.item)"
                ><i class="ri-ball-pen-fill ml-1"></i
              ></b-button>
              <b-button
                variant=" iq-bg-primary rounded"
                @click="
                  $router.push({
                    name: 'admin.show-contract',
                    params: {
                      id: data.item.id,
                      companyId: $route.params.id,
                    },
                  })
                "
                class="mx-1"
                size="sm"
                ><i class="ri-eye-fill ml-1"></i
              ></b-button>
              <b-button
                variant=" iq-bg-danger rounded"
                size="sm"
                @click="deleteItem(data.item.id)"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
        </b-col>
      </template>
    </iq-card>
    <modal-form-contract ref="modalFormContract" />
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormContract from "./createOrUpdateContract.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  name: "Contracts",
  components: {
    modalFormContract,
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    ...mapActions({
      fetchContracts: "fetchContracts",
      deleteContract: "deleteContract",
    }),
    updateItem(data) {
      this.$refs.modalFormContract.show(data);
    },
    async getData() {
      await this.fetchContracts({
        company_id: this.$route.params.id,
      });
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteContract(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      isBusy: false,
      moment,
    };
  },
  computed: {
    ...mapGetters({
      contracts: "contracts",
      contractsLoading: "contractsLoading",
    }),
    columns() {
      return [
        {
          label: this.$t("contracts.service"),
          key: "ad_service.name",
          class: "text-center",
        },
        {
          label: this.$t("contracts.expired-date"),
          key: "expired_date",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
<style>
.content-width {
  max-width: 460px !important;
}
</style>
